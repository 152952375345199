import "core-js/modules/es.array.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'ButtonCustom',
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    icon: {
      type: undefined,
      required: false,
      default: null
    },
    appendIcon: {
      type: undefined,
      required: false,
      default: null
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    size: {
      type: String,
      required: false,
      default: 'long'
    },
    variant: {
      type: String,
      required: false,
      default: 'secondary',
      validator: function validator(value) {
        return ['primary', 'secondary', 'tertiary', 'icon'].includes(value);
      }
    },
    route: {
      type: undefined,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    minimized: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    handleClick: function handleClick() {
      this.$emit('click');
    }
  }
};