import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'Spinner',
  props: {
    size: {
      type: [String, Number],
      required: false,
      default: '25'
    },
    theme: {
      type: String,
      required: false,
      default: 'light',
      validator: function validator(value) {
        return ['light', 'dark'].includes(value);
      }
    }
  }
};